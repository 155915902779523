<template>
  <div class="my-footer">
    <div class="w70">
      <div class="footer-text">Copyright ©2024 All Rights Reserved</div>
      <div class="footer-text">备案号：湘ICP备2023013559号&nbsp;&nbsp;&nbsp;&nbsp;技术支持：深圳易函云技术有限公司</div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
.my-footer {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(15, 15, 15, 1);
}
.w70 {
  width: 1300px;
  padding: 30px 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}
</style>