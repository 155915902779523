<template>
  <div class="my-banner">
    <div class="box-banner">
      <div class="banner-img">
        <div class="banner-text" @click="jump">怀集县农民工用工管理平台</div>
        <div style="display:flex;">
          <div class="wec-box" v-if="AlreadyLanded">
            <div class="wec-name">
              <div class="wec">欢迎登陆，</div>
              <div class="name">{{username.real_name}}</div>
            </div>
            <div class="login-box">
              <div class="wec-icon" @click="PersonalCenter">
                <div>
                  <el-icon class="is-loading">
                    <Tickets />
                  </el-icon>
                </div>
                <div class="tit-le">个人中心</div>
              </div>
              <div style="width: 100%;height:0; border: 1px solid rgba(241, 241, 241, 1);margin:10px 0;"></div>
              <div class="wec-icon" @click="Quit">
                <div>
                  <el-icon>
                    <SwitchButton />
                  </el-icon>
                </div>
                <div class="tit-le">退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-new">
      <div class="w1300">
        <!-- <div class="banner-new-text" @click="router.push('/')">首页</div>
        <div class="banner-new-text" @click="depositChange">在线开函</div>
        <div class="banner-new-text" @click="router.push('/newsList')">新闻动态</div>
        <div class="banner-new-text" @click="router.push('/partners')">合作伙伴</div>
        <div class="banner-new-text" @click="router.push('/aboutUs')">关于我们</div>
        <div class="banner-new-text" @click="share(3)">合同管理</div>
        <div class="banner-new-text" @click="share(4)">班组管理</div>
        <div class="banner-new-text" @click="share(5)">工人管理</div>
        <div class="banner-new-text" @click="share(6)">工人进退场</div> -->
        <div v-for="item in bannerTitle" :key="item.id" :class='["banner-new-text",{"active":item.id==activeBanner}]' @click="handleClick(item.id, item.num,item.route)">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from "vue-router";
import { refreshToken, searchCompany } from "@/api/index.js"
import { ElMessage, ElMessageBox } from 'element-plus'
let router = useRouter()
let AlreadyLanded = ref(false)
let username = ref()
let activeBanner = ref(1)
let bannerTitle = ref([
  { text: '首页', route: '/', id: 1, num: 1 },
  { text: '项目申报', route: '/deposit', id: 2, num: 2 },
  // { text: '新闻动态', route: '/newsList', id: 3, num: 1 },
  // { text: '合作伙伴', route: '/partners', id: 4, num: 1 },
  // { text: '关于我们', route: '/aboutUs', id: 5, num: 1 },
  { text: '合同管理', route: '/personalCenter', id: 6, num: 3 },
  { text: '班组管理', route: '/personalCenter', id: 7, num: 4 },
  { text: '工人管理', route: '/personalCenter', id: 8, num: 5 },
  // { text: '工人进退场', route: '/personalCenter', id: 9, num: 6 },
  { text: '新闻动态', route: '/newsList', id: 3, num: 1 },
])
let show = ref(true)
let handleClick = (id, num, route) => {
  localStorage.setItem('activeBanner', id)
  if (num >= 3) {
    share(num)
  } else if (num == 2) {
    depositChange()
  } else {
    router.push(route)
  }
}
let jump = () => {
  router.push("/")
}
let mes = () => {
  searchCompany().then(res => {
    if (res.data.state == 99 || res.data.state == 1) {
      show.value = false
    } else {
      show.value = true
    }
  })
}
let depositChange = () => {
  console.log(AlreadyLanded.value, 'AlreadyLanded.value');
  if (!AlreadyLanded.value) {
    router.push("/deposit")
  } else {
    searchCompany().then(res => {
      if (res.data.state == 99 || res.data.state == 1) {
        return ElMessage.warning('注册信息审核中，暂无权限')
      } else {
        router.push("/deposit")
      }
    })
  }
}
let PersonalCenter = () => {
  searchCompany().then(res => {
    if (res.data.state == 99 || res.data.state == 1) {
      router.push("/enterpriseInformationManagement")
      localStorage.setItem('active', '7');
    } else {
      router.push("/personalCenter")
      localStorage.setItem('active', '1');
    }
  })
}
let Quit = () => {
  ElMessageBox.confirm('确定要退出登录吗？', '退出登录', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    confirmButtonClass: 'sure',
    cancelButtonClass: 'sure',
    type: 'warning'
  }).then(() => {
    ElMessage({
      type: 'success',
      message: '退出成功!'
    })
    localStorage.removeItem('token');
    localStorage.removeItem('tokenuser');
    localStorage.removeItem('Time');
    localStorage.setItem('activeBanner', 1)
    router.push("/").then(() => {
      window.location.reload();
    })
  }).catch(() => {
    return
  })
}
let share = (num) => {
  if (!show.value) {
    ElMessage.warning('注册信息审核中，暂无权限')
    return
  }
  localStorage.setItem('active', num)
  router.push('/personalCenter').then(() => {
    location.reload();
  })
}
let refreshTokenMessage = () => {
  setInterval(async () => {
    let nowTime = new Date().getTime() + 1000 * 60 * 60 * 24 * 7
    let loginTime = localStorage.getItem('Time')
    let TimeDifference = nowTime - loginTime
    if (TimeDifference >= 3000) {
      localStorage.setItem('Time', new Date().getTime() + 1000 * 60 * 60 * 24 * 7)
      await refreshToken(JSON.parse(localStorage.getItem('token')).refresh_token).then(res => {
        let tokens = JSON.parse(localStorage.getItem('token'));
        tokens.refresh_token = res.refresh_token;
        tokens.access_token = res.access_token;
        localStorage.setItem('token', JSON.stringify(tokens));
        localStorage.setItem('tokenuser', res.access_token);
      })
    }
  }, 10 * 60 * 1000)
}
onMounted(async () => {
  activeBanner.value = localStorage.getItem('activeBanner') ? localStorage.getItem('activeBanner') : 1
  if (localStorage.getItem('tokenuser') !== null) {
    mes()
  }
  username.value = JSON.parse(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')) : ''
  if (username && username.value.access_token) {
    AlreadyLanded.value = true
  } else {
    AlreadyLanded.value = false
  }
  refreshTokenMessage()
})
</script>
<style scoped>
.w1300 {
  width: 1300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active {
  font-weight: 700;
}
.banner-new-text {
  min-width: 215px;
  height: 24px;
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  text-align: center;
}
.banner-new-text:nth-last-child(1) {
  border-right: none;
}
.banner-new-text:hover {
  font-weight: 700;
}
.banner-new {
  height: 70px;
  width: 100%;
  background: rgb(0, 82, 217);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-ico {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form {
  width: 403px;
  height: 100%;
  padding: 0 52px;
  box-sizing: border-box;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.login-title {
  font-size: 30px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  margin-top: 75px;
}
.login-method {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  display: flex;
  margin-bottom: 44px;
}
img {
  vertical-align: middle;
}
.ico {
  vertical-align: middle;
}
.my-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 999 !important;
}
.box-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.banner-img {
  width: 1300px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner-text {
  font-size: 36px;
  font-weight: 700;
  line-height: 52.13px;
  color: rgba(0, 82, 217, 1);
  cursor: pointer;
}
.banner-btn {
  width: 120px;
  height: 40px;
  border-radius: 6px;
  background: linear-gradient(
    135deg,
    rgba(67, 200, 238, 1) 0%,
    rgba(106, 120, 252, 1) 100%
  );
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.wec-name {
  display: flex;
  align-items: center;
}
.wec-box {
  position: relative;
}
.wec {
  font-size: 16px;
  font-weight: 400;
  line-height: 23.17px;
  color: rgba(51, 51, 51, 1);
}
.name {
  font-size: 16px;
  color: rgba(31, 112, 247, 1);
  cursor: pointer;
}
.el-icon.is-loading {
  animation: none;
  vertical-align: middle;
}
.wec-icon {
  display: flex;
  cursor: pointer;
  color: rgba(51, 51, 51, 1);
}
.tit-le {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.27px;
  margin-left: 10px;
  white-space: nowrap;
}
.login-box {
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  z-index: 999;
  position: absolute;
  left: 0;
  display: none;
}
.wec-icon:hover {
  color: rgb(0, 82, 217);
}
.wec-box:hover .login-box {
  display: block;
}
</style>